import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { Form, Formik } from "formik";
import {FormHelperText} from '@mui/material';
import { OPTIONS_DISCOUNT, OPTIONS_NAME, OPTIONS_PRODUCT } from "../../../constants/optionsSpot";
import { initialValues, validationSchema } from "./formModel";
import spotPablo from '../../../../assets/images/spot_pablo.png';
import spotVianny from '../../../../assets/images/spot_vianny.png';
import Modal from 'react-modal';
import "./VideoOptions.scss";

// import { GetLocalizedStrings } from "../../../../utils";
// import { demo } from "../../../../constants/Sentences";
// import TagManagerService from "../../../../services/google-tag-manager/tag-manager.service";
// import TagManager from "react-gtm-module";
// import FormHelperText from "@material-ui/core/FormHelperText";

const BASE_VIDEO_URL = "https://myme-public.s3-eu-west-1.amazonaws.com/spot_videos/";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};


const VideoOptions = ({ setVideoUrl, setCtaText, setCtaUrl, setLoop }) => {
    const [language] = useCookies(["language"]) || "es";
    const [strings, setStrings] = useState();

    // Modal
    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }



    // useEffect(() => {
    //   setStrings(GetLocalizedStrings(demo));
    // }, [language]);

    const handleSetVideoUrl = (values) => {
        const { name, product, discount, prescriptor, cta_text, cta_url } = values;
        setVideoUrl(BASE_VIDEO_URL.concat(name, "-", product, "-", discount, "-", prescriptor, "-client.mp4"));
        setCtaText(cta_text);
        setCtaUrl(cta_url);
        // window.scrollTo(0, 0);
        setLoop(false);
    };

    // TagManager.dataLayer({ event: "Spot2" });

    // const gtmSpotButtonEvent = () => {
    //   TagManagerService.pushEvent("ClickSpotButton");
    // };

    return (
        // Formulario Contacto Mobile
        <>
            {window.innerWidth < 992 ?
                <div className="col-md-12">
                    <div className="video-mobile">
                        <button className="btn btn-primary" onClick={openModal}>Prueba aquí</button>
                        {/*<button*/}
                        {/*    className="toggle-button btn btn-primary"*/}
                        {/*    id="centered-toggle-button"*/}
                        {/*    onClick={e => {*/}
                        {/*        this.showModal(e);*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    {" "}*/}
                        {/*    Enviar mensaje{" "}*/}
                        {/*</button>*/}

                        <Modal
                            isOpen={modalIsOpen}
                            onRequestClose={closeModal}
                            style={customStyles}
                            contentLabel="Example Modal"
                        >
                            <div className="video-options-container">
                                <div className="video-options-subcontainer">
                                    <div className="div-close">
                                        <button className="button-close" onClick={closeModal}>Cerrar</button>
                                    </div>
                                    <div className="text-container">
                                        <h3 className="text-1">¿Cómo funciona Myme?</h3>
                                        <p className="text-2">Personaliza el vídeo y descúbrelo</p>
                                    </div>


                                    <Formik
                                        initialValues={initialValues}
                                        validationSchema={validationSchema}
                                        onSubmit={(values) => {
                                            handleSetVideoUrl(values);
                                            closeModal(false);
                                        }}
                                    >
                                        {({values, errors, touched, handleChange}) => {
                                            return (
                                                <Form className="">
                                                    <div className="radio-inputs-container">
                                                        <p className="text-choose">Elige presentador/a:</p>
                                                        <div
                                                            className={`radio-input-container ${values.prescriptor === "pablo" && "selected"}`}>
                                                            <input
                                                                // className="demo-check"
                                                                type="radio"
                                                                name="prescriptor"
                                                                value="pablo"
                                                                id="spot_pablo"
                                                                onChange={handleChange}
                                                                checked={values.prescriptor === "pablo"}
                                                            />
                                                            <label htmlFor="spot_pablo">
                                                                <img src={spotPablo}/>
                                                            </label>
                                                        </div>

                                                        <div
                                                            className={`radio-input-container ${values.prescriptor === "vianny" && "selected"}`}>
                                                            <input
                                                                // className="demo-check"
                                                                type="radio"
                                                                name="prescriptor"
                                                                value="vianny"
                                                                id="spot_vianny"
                                                                onChange={handleChange}
                                                                checked={values.prescriptor === "vianny"}
                                                            />
                                                            <label htmlFor="spot_vianny">
                                                                <img src={spotVianny}/>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="form-container">
                                                        <div className="form-container-1">
                                                            <div className="select-container">
                                                                <p className="label-text">Nombre*</p>
                                                                <select
                                                                    name="name"
                                                                    className={`${values.name === null && "select-placeholder"} ${
                                                                        touched.name && errors.name && "select-error"
                                                                    }`}
                                                                    onChange={handleChange}
                                                                    defaultValue={'Nombre'}
                                                                >
                                                                    <option disabled>
                                                                        Nombre
                                                                    </option>
                                                                    {OPTIONS_NAME.map((option) => (
                                                                        <option key={option.VALUE} value={option.VALUE}>
                                                                            {option.LABEL}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <div className="select-container">
                                                                <p className="label-text">Plantilla*</p>
                                                                <select
                                                                    name="product"
                                                                    className={`${values.product === null && "select-placeholder"} ${
                                                                        touched.product && errors.product && "select-error"
                                                                    }`}
                                                                    onChange={handleChange}
                                                                    defaultValue={'Opción'}
                                                                >
                                                                    <option disabled>
                                                                        Opción
                                                                    </option>
                                                                    {OPTIONS_PRODUCT.map((option) => (
                                                                        <option key={option.VALUE} value={option.VALUE}>
                                                                            {option.LABEL}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="form-container-1">
                                                            <div className="select-container">
                                                                <p className="label-text">Descuento*</p>
                                                                <select
                                                                    name="discount"
                                                                    className={`${values.discount === null && "select-placeholder"} ${
                                                                        touched.discount && errors.discount && "select-error"
                                                                    }`}
                                                                    onChange={handleChange}
                                                                    defaultValue={'Descuento'}
                                                                >
                                                                    <option disabled>
                                                                        Descuento
                                                                    </option>
                                                                    {OPTIONS_DISCOUNT.map((option) => (
                                                                        <option key={option.VALUE} value={option.VALUE}>
                                                                            {option.LABEL}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <div>
                                                                <p className="label-text">Texto del botón*</p>
                                                                <input
                                                                    type="text"
                                                                    // className="text-input"
                                                                    className={`text-input ${values.cta_text === "" && "input-placeholder"} ${
                                                                        touched.cta_text && errors.cta_text && "input-error"
                                                                    }`}
                                                                    placeholder="Texto botón CTA"
                                                                    name="cta_text"
                                                                    value={values.cta_text}
                                                                    onChange={handleChange}
                                                                    maxLength="20"
                                                                ></input>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <p className="label-text">Enlace de tu web*</p>
                                                            <input
                                                                type="text"
                                                                // className="text-input input-url"
                                                                className={`text-input input-url ${values.cta_url === "" && "input-placeholder"} ${
                                                                    touched.cta_url && errors.cta_url && "input-error"
                                                                }`}
                                                                placeholder="http://tuweb.es"
                                                                name="cta_url"
                                                                value={values.cta_url}
                                                                onChange={handleChange}
                                                            ></input>
                                                        </div>
                                                    </div>

                                                    <div className="button-container">
                                                        {/*<button className="spot-button" type="submit" onClick={() => gtmSpotButtonEvent()}>*/}
                                                        <button className="spot-button" type="submit">
                                                            ¡Quiero verlo!
                                                        </button>
                                                    </div>
                                                    <FormHelperText error className="error-message">
                                                        {/* <p className="error-message">{Object.keys(errors).length !== 0 && "*Campos obligatorios"}</p> */}
                                                        *Campos obligatorios
                                                    </FormHelperText>
                                                </Form>
                                            );
                                        }}
                                    </Formik>
                                </div>
                            </div>
                        </Modal>
                    </div>
                </div>

                :

                // Form Video Desktop
                <div className="video-options-container">
                    <div className="video-options-subcontainer">
                        <div className="text-container">
                            <h3 className="text-1">¿Cómo funciona Myme?</h3>
                            <p className="text-2">Personaliza el vídeo y descúbrelo</p>
                        </div>

                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={(values) => {
                                handleSetVideoUrl(values);
                            }}
                        >
                            {({values, errors, touched, handleChange}) => {
                                return (
                                    <Form className="">
                                        <div className="radio-inputs-container">
                                            <p className="text-choose">Elige presentador/a:</p>
                                            <div
                                                className={`radio-input-container ${values.prescriptor === "pablo" && "selected"}`}>
                                                <input
                                                    // className="demo-check"
                                                    type="radio"
                                                    name="prescriptor"
                                                    value="pablo"
                                                    id="spot_pablo"
                                                    onChange={handleChange}
                                                    checked={values.prescriptor === "pablo"}
                                                />
                                                <label htmlFor="spot_pablo">
                                                    <img src={spotPablo}/>
                                                </label>
                                            </div>

                                            <div
                                                className={`radio-input-container ${values.prescriptor === "vianny" && "selected"}`}>
                                                <input
                                                    // className="demo-check"
                                                    type="radio"
                                                    name="prescriptor"
                                                    value="vianny"
                                                    id="spot_vianny"
                                                    onChange={handleChange}
                                                    checked={values.prescriptor === "vianny"}
                                                />
                                                <label htmlFor="spot_vianny">
                                                    <img src={spotVianny}/>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="form-container">
                                            <div className="form-container-1">
                                                <div className="select-container">
                                                    <p className="label-text">Nombre*</p>
                                                    <select
                                                        name="name"
                                                        className={`${values.name === null && "select-placeholder"} ${
                                                            touched.name && errors.name && "select-error"
                                                        }`}
                                                        onChange={handleChange}
                                                        defaultValue={'Nombre'}
                                                    >
                                                        <option disabled>
                                                            Nombre
                                                        </option>
                                                        {OPTIONS_NAME.map((option) => (
                                                            <option key={option.VALUE} value={option.VALUE}>
                                                                {option.LABEL}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="select-container">
                                                    <p className="label-text">Plantilla*</p>
                                                    <select
                                                        name="product"
                                                        className={`${values.product === null && "select-placeholder"} ${
                                                            touched.product && errors.product && "select-error"
                                                        }`}
                                                        onChange={handleChange}
                                                        defaultValue={'Opción'}
                                                    >
                                                        <option disabled>
                                                            Opción
                                                        </option>
                                                        {OPTIONS_PRODUCT.map((option) => (
                                                            <option key={option.VALUE} value={option.VALUE}>
                                                                {option.LABEL}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="form-container-1">
                                                <div className="select-container">
                                                    <p className="label-text">Descuento*</p>
                                                    <select
                                                        name="discount"
                                                        className={`${values.discount === null && "select-placeholder"} ${
                                                            touched.discount && errors.discount && "select-error"
                                                        }`}
                                                        onChange={handleChange}
                                                        defaultValue={'Descuento'}
                                                    >
                                                        <option disabled>
                                                            Descuento
                                                        </option>
                                                        {OPTIONS_DISCOUNT.map((option) => (
                                                            <option key={option.VALUE} value={option.VALUE}>
                                                                {option.LABEL}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div>
                                                    <p className="label-text">Texto del botón*</p>
                                                    <input
                                                        type="text"
                                                        // className="text-input"
                                                        className={`text-input ${values.cta_text === "" && "input-placeholder"} ${
                                                            touched.cta_text && errors.cta_text && "input-error"
                                                        }`}
                                                        placeholder="Texto botón CTA"
                                                        name="cta_text"
                                                        value={values.cta_text}
                                                        onChange={handleChange}
                                                        maxLength="20"
                                                    ></input>
                                                </div>
                                            </div>
                                            <div>
                                                <p className="label-text">Enlace de tu web*</p>
                                                <input
                                                    type="text"
                                                    // className="text-input input-url"
                                                    className={`text-input input-url ${values.cta_url === "" && "input-placeholder"} ${
                                                        touched.cta_url && errors.cta_url && "input-error"
                                                    }`}
                                                    placeholder="http://tuweb.es"
                                                    name="cta_url"
                                                    value={values.cta_url}
                                                    onChange={handleChange}
                                                ></input>
                                            </div>
                                        </div>

                                        <div className="button-container">
                                            {/*<button className="spot-button" type="submit" onClick={() => gtmSpotButtonEvent()}>*/}
                                            <button className="spot-button" type="submit">
                                                ¡Quiero verlo!
                                            </button>
                                        </div>
                                        <FormHelperText error className="error-message">
                                            {/* <p className="error-message">{Object.keys(errors).length !== 0 && "*Campos obligatorios"}</p> */}
                                            *Campos obligatorios
                                        </FormHelperText>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </div>
                </div>
            }
        </>
    );
};

export default VideoOptions;
