import React, { useState, useRef, useEffect } from "react";
import { Player } from "video-react";
import "../../../../../node_modules/video-react/dist/video-react.css";
// import { VolumeMuteIcon, VolumeUpIcon } from "../../../../assets/svg/homepage";
import "./VideoPlayer.scss";
import muteVol from "../../../../assets/svg/volume-mute.svg";
import upVol from "../../../../assets/svg/volume-up.svg";

const VideoPlayer = ({ videoUrl, loop = true, autoPlay = true, ctaText, ctaUrl }) => {
    const player = useRef();
    const [playerState, setPlayerState] = useState(player);
    const [muted, setMuted] = useState(true);
    const [showCtaButton, setShowCtaButton] = useState(false);

    useEffect(() => {
        player &&
        player.current.subscribeToStateChange((state) => setPlayerState(state)) &&
        (player.current.actions.toggleFullscreen = () => {});
    }, []);

    useEffect(() => {
        setShowCtaButton(playerState.duration > 0 && playerState.duration - playerState.currentTime < 5);
    }, [playerState.currentTime]);

    return (
        <div className="player-container">
            <div className="volume-icon" onClick={() => setMuted(!muted)}>
                {playerState.muted ?
                    <img src={muteVol}  />
                    : <img src={upVol}  /> }
            </div>
            {showCtaButton && (
              <button className="cta-button" onClick={() => window.open(ctaUrl, "_blank")}>
                {ctaText}
              </button>
            )}

            {/*vídeo*/}
            <div className="spot-video" id="spot">
                <Player playsInline ref={player} muted={muted} autoPlay={autoPlay} src={videoUrl} />
            </div>
            {/*{showCtaButton && (*/}
            {/*    <div className="div-cta-button" >*/}
            {/*        <p>Haz click en el botón:</p>*/}
            {/*        <button className="cta-button" onClick={() => window.open(ctaUrl, "_blank")}>*/}
            {/*            {ctaText}*/}
            {/*        </button>*/}
            {/*    </div>*/}
            {/*)}*/}
        </div>
    );
};

export default VideoPlayer;
