import * as yup from "yup";

const initialValues = {
  name: null,
  product: null,
  discount: null,
  cta_text: "",
  cta_url: "",
  prescriptor: "pablo",
};

const validationSchema = yup.object().shape({
  name: yup.string(),
  product: yup.string(),
  discount: yup.string(),
  prescriptor: yup.string(),
  cta_text: yup.string().required(),
  // cta_url: yup.string().url(),
  cta_url: yup
    .string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/
    )
    .required(),
});

export { initialValues, validationSchema };
