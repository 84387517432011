export const cookieSettingsModalConstants = {
  es: {
    title: "CONFIGURACIÓN DE COOKIES",
    always_active: "Siempre activas",
    cookie_info: [
      {
        value: "info1",
        name: "Tu privacidad",
        description1:
          "Cuando visitas cualquier sitio web, el mismo podría obtener o guardar información en tu navegador, generalmente mediante el uso de cookies. Esta información puede ser acerca de ti, tus preferencias o tu dispositivo, y se usa principalmente para que el sitio funcione según lo esperado. La información no te identifica directamente, pero puede proporcionar una experiencia web más personalizada. Ya que respetamos su derecho a la privacidad, puedes escoger no permitirnos usar ciertas cookies. Haz clic en los encabezados de cada categoría para saber más y cambiar nuestras configuraciones predeterminadas. Sin embargo, el bloqueo de algunos tipos de cookies puede afectar tu experiencia en el sitio y los servicios que podemos ofrecer.",
        descriptionLink: "Más información de las cookies",
      },
    ],
    cookie_types: [
      {
        value: "strict",
        name: "Cookies estrictamente necesarias",
        description1:
          "Estas cookies son estrictamente necesarias para que el sitio web funcione. Están configuradas para responder a acciones hechas por el usuario para recibir servicios, tales como ajustar tus preferencias de privacidad, iniciar sesión en el sitio, o llenar formularios, para que podamos prestarle nuestro servicio. También nos ayudan a aplicar nuestros Términos de Uso, evitar estafas y mantener la seguridad de nuestro servicio.",
        description2:
          "Puedes configurar tu navegador para bloquear o alertar la presencia de estas cookies, pero algunas partes del sitio web no funcionarán. Estas cookies no guardan ninguna información personal identificable.",
        description3:
          "Vida útil: la mayoría de las cookies son de sesión (se desactivan en cuanto cierras el navegador) o cookies que solo tienen un día de duración. Algunas cookies están activas durante más tiempo (entre 3 y 12 meses). Las cookies que sirven para impedir los fraudes y proteger nuestros servicios están activas durante 24 meses como máximo.",
        selectable: false,
        default: true, //non-selectable types should always be true by default
      },
      {
        value: "functional",
        name: "Cookies funcionales y de rendimiento",
        description1:
          "Estas cookies nos permiten contar las visitas y fuentes de tráfico para poder evaluar el rendimiento de nuestro sitio y mejorarlo. Nos ayudan a saber cómo usas nuestra página web, qué secciones son las más o las menos visitadas, y al mismo tiempo poder ofrecerte información sobre nuestros productos y servicios que puedan ser de tu interés durante la navegación por la web y llevar a cabo investigaciones de mercado. La eliminación de estos tipos de cookies puede limitar la funcionalidad de nuestro servicio.",
        description2:
          "Vida útil: la mayoría de estas cookies solo duran un día. Algunas cookies están activas durante más tiempo (entre 3 y 12 meses).",
        selectable: true,
        default: true,
      },
      // { value: "addvertising", name: "Cookies publicitarias", description: "Estas cookies ... .", selectable: true },
    ],
    accept_button: "Aceptar",
    cancel_button: "Cancelar",
  },
};
