import axios from "axios";

import authHeader from "./auth-header";


const API_URL =
  process.env.REACT_APP_ENV.toLowerCase() === "local" ? "/api/" : process.env.REACT_APP_DEV_PROXY + "/api/";
// const SERVER_URL = process.env.REACT_APP_DEV_PROXY + "/api/";
// const SERVER_URL = "http://localhost:8000/api/";



const send_contact = async (name, email, subject, text, to, check) => {
  return axios
    .post(API_URL + `send-contact/`, {
      name,
      email,
      subject,
      text,
      to,
      check,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};


export default {
  send_contact,
};
