import React from 'react';
import Spot from './Spot';

class HowWeWork extends React.Component {
    render(){
        return (
            <section id="whatismyme" className="how-work ptb-80">
                <div className="container">
                    <div className="section-title">
                        <h2>¿Qué es <span>Myme?</span></h2>
                        <p className="text "> <span className="bold">Myme es una herramienta que nace con el objetivo de democratizar la comunicación personalizada.</span> <br/>
                            Mediante la suscripción a nuestra plataforma y gracias a un sencillo sistema de plantillas, podrás cubrir el 95% de las necesidades comunicativas de tu empresa y hacer mensajes exclusivos sin perder el trato personal con tus clientes ¡Pruébanos!
                        </p>
                    </div>

                    <div className="row">
                        <Spot/>

                    </div>
                </div>
            </section>
        );
    }
}

export default HowWeWork;