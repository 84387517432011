import React from 'react';
import { Link } from 'react-router-dom';

class Price extends React.Component {
    render(){
        return (
            <section id="price" className="price-area ptb-80 bg-f6f6f6">
                <div className="container">
                    <div className="section-title">
                        <h2>Nuestros <span>Planes</span></h2>
                        <p>Myme es disfrutar de soluciones flexibles para tu empresa.</p>
                        <p className="maxWidth">Hemos diseñado nuestros planes para que se ajusten a las características de tu negocio.</p>
                        <p>Además, podrás cambiar de plan siempre que quieras.</p>
                        {/*<h4 className="paddingTop">Nuetros <span>Paquetes:</span></h4>*/}
                    </div>

                    <div className="row row-price">
                        <div className="col-lg-3 col-md-6">
                            <div className="pricingTable">
                                <h3 className="title">Plan FREE</h3>
                                <div className="price-value">
                                    {/*<span className="currency">$</span>*/}
                                    <span className="amount">50</span>
                                    <span className="month">videos/mes</span>
                                </div>

                                <ul className="pricing-content">
                                    <li><i className="fa fa-check"></i> Gratuito</li>
                                    <li><i className="fa fa-check"></i> Tiempo Ilimitado</li>
                                </ul>

                                <a href={`${process.env.REACT_APP_BASE_URL}/signup?plan_name=Free&plan_type=M`}
                                   className="btn btn-primary">
                                    Pruébalo
                                </a>

                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                            <div className="pricingTable">
                                <h3 className="title">Plan STANDARD</h3>
                                <div className="price-value">
                                    {/*<span className="currency">$</span>*/}
                                    <span className="amount">250</span>
                                    <span className="month">videos/mes</span>
                                </div>
                                <ul className="pricing-info">
                                    <li><i className="pricing-bold"></i> <b>9,95€ / Pago mensual </b></li>
                                    <li><i className=""></i> 89,95€ / Pago anual</li>
                                </ul>
                                <ul className="pricing-content">
                                    <li><i className="fa fa-check"></i> Acceso a todas las plantillas</li>
                                    <li><i className="fa fa-check"></i> Caducidad de un mes</li>
                                    <li><i className="fa fa-check"></i> Estadísticas de las campañas</li>
                                    <li><i className="fa fa-check"></i> Selección de protagonista</li>
                                    <li><i className="fa fa-check"></i> Elegir la caducidad del mensaje</li>
                                    <li><i className="fa fa-check"></i> Normalización de datos</li>
                                    <li><i className="fa fa-check"></i> Incluir tu logo</li>
                                </ul>

                                <a href={`${process.env.REACT_APP_BASE_URL}/signup?plan_name=Standard&plan_type=M`}
                                   className="btn btn-primary">
                                    Pruébalo
                                </a>

                            </div>
                        </div>


                        <div className="col-lg-3 col-md-6">
                            <div className="pricingTable pricingTable-shadow">
                                <h3 className="title">Plan PRO</h3>
                                <div className="price-value">
                                    {/*<span className="currency">$</span>*/}
                                    <span className="amount">500</span>
                                    <span className="month">videos/mes</span>
                                </div>
                                <ul className="pricing-info">
                                    <li><i className="pricing-bold"></i> <b>14,95€ / Pago mensual</b></li>
                                    <li><i className=""></i> 119,95€ / Pago anual</li>
                                </ul>
                                <ul className="pricing-content">
                                    <li><i className="fa fa-check"></i> Acceso a todas las plantillas</li>
                                    <li><i className="fa fa-check"></i> Caducidad de un mes</li>
                                    <li><i className="fa fa-check"></i> Estadísticas de las campañas</li>
                                    <li><i className="fa fa-check"></i> Selección de protagonista</li>
                                    <li><i className="fa fa-check"></i> Elegir la caducidad del mensaje</li>
                                    <li><i className="fa fa-check"></i> Normalización de datos</li>
                                    <li><i className="fa fa-check"></i> Incluir tu logo</li>
                                </ul>

                                <a href={`${process.env.REACT_APP_BASE_URL}/signup?plan_name=Pro&plan_type=M`}
                                   className="btn btn-primary">
                                    Pruébalo
                                </a>

                            </div>
                        </div>

                        {/*<div className="col-lg-3 col-md-6 offset-lg-0">*/}
                        {/*    <div className="pricingTable">*/}
                        {/*        <h3 className="title">Plan ÉLITE</h3>*/}
                        {/*        <div className="price-value">*/}
                        {/*            /!*<span className="currency">$</span>*!/*/}
                        {/*            <span className="amount">500</span>*/}
                        {/*            <span className="month">videos/mes</span>*/}
                        {/*        </div>*/}
                        {/*        <ul className="pricing-info">*/}
                        {/*            <li><i className="pricing-bold"></i> <b>24,95€ / Pago mensual</b></li>*/}
                        {/*            <li><i className=""></i> 169,95€ / Pago anual</li>*/}
                        {/*        </ul>*/}
                        {/*        <ul className="pricing-content">*/}
                        {/*            <li><i className="fa fa-check"></i> Acceso a todas las plantillas</li>*/}
                        {/*            <li><i className="fa fa-check"></i> Caducidad de un mes</li>*/}
                        {/*            <li><i className="fa fa-check"></i> Estadísticas de las campañas</li>*/}
                        {/*            <li><i className="fa fa-check"></i> Selección de protagonista</li>*/}
                        {/*            <li><i className="fa fa-check"></i> Elegir la caducidad del mensaje</li>*/}
                        {/*            <li><i className="fa fa-check"></i> Normalización de datos</li>*/}
                        {/*            <li><i className="fa fa-check"></i> Incluir tu logo</li>*/}
                        {/*        </ul>*/}
                        {/*        /!*<Link to="/https://app.myme.net/home" className="btn btn-primary">Order Now</Link>*!/*/}
                        {/*        /!*<Link*!/*/}
                        {/*        /!*    // target="_blank"*!/*/}
                        {/*        /!*    to={{*!/*/}
                        {/*        /!*        pathname: "https://app.myme.net/signup",*!/*/}
                        {/*        /!*        data: { type: 'M', name: 'Elite' },*!/*/}
                        {/*        /!*    }}>*!/*/}
                        {/*        /!*    <button className="btn btn-primary" >Pruébalo</button>*!/*/}
                        {/*        /!*</Link>*!/*/}

                        {/*        <a href="https://app.myme.net/signup?plan_name=Elite&plan_type=M"*/}
                        {/*           className="btn btn-primary">*/}
                        {/*            Pruébalo*/}
                        {/*        </a>*/}

                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </section>
        );
    }
}

export default Price;