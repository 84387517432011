export const OPTIONS_NAME = [
  {
    VALUE: "carmen",
    LABEL: "Carmen",
  },
  {
    VALUE: "pedro",
    LABEL: "Pedro",
  },
  {
    VALUE: "luisa",
    LABEL: "Luisa",
  },
  {
    VALUE: "oswaldo",
    LABEL: "Oswaldo",
  },
  {
    VALUE: "mariaguadalupe",
    LABEL: "María Guadalupe",
  },
];
export const OPTIONS_PRODUCT = [
  {
    VALUE: "st_valentin",
    LABEL: "San Valentín",
  },
  {
    VALUE: "dia_madre",
    LABEL: "Día de la Madre",
  },
];
export const OPTIONS_PRESCRIPTOR = [
  {
    VALUE: "pablo",
    LABEL: "Pablo",
  },
  {
    VALUE: "vianny",
    LABEL: "Vianny",
  },
];
export const OPTIONS_DISCOUNT = [
  {
    VALUE: "2por1",
    LABEL: "2x1",
  },
  {
    VALUE: "10",
    LABEL: "10%",
  },
  {
    VALUE: "30",
    LABEL: "30%",
  },
  {
    VALUE: "60",
    LABEL: "60%",
  },
];
