import React from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from 'react-accessible-accordion';

class Faq extends React.Component {
    render(){
        return (
            <section id="faq" className="faq-area ptb-80">
                <div className="container">
                    <div className="section-title">
                        <h2><span>Preguntas</span> Frecuentes</h2>
                        <p>Resolvemos tus dudas  </p>
                        {/*<p>¿Tienes más preguntas? Escríbemos a <a href="mailto:hello@myme.net">hello@myme.net</a></p>*/}
                    </div>

                    <div className="row">
                        <div className="col-lg-7 col-md-12">
                            <div className="accordion" id="accordionEx" role="tablist" aria-multiselectable="true">
                                <Accordion>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                ¿Para quién es Myme?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                Myme es el aliado perfecto para aumentar las ventas de tu negocio de una manera sencilla, cercana y económica.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                ¿Cuáles son los métodos de pago disponibles?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                A través de la plataforma Stripe, Myme te permite realizar tus pagos con tarjeta de débido o crédito, sin preocuparte por problemas de seguridad.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                ¿Existe permanencia?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                Myme es una plataforma de suscripción. Cuando te suscribes a un plan, aceptas una facturación recurrente mensual o anual, pero no te preocupes, porque podrás cancelarla en cualquier momento y de una manera tan sencilla como darse de alta.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                ¿Qué es la dicción en boca?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                La dicción en boca es uno de los grandes logros de la tecnología Myme, que consigue que los presentadores de tus vídeos digan el nombre de cada cliente o empleado al que te diriges. De esta forma, cualquier nombre de tu base de datos se transforma en un vídeo único y personalizado.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                ¿En qué idiomas esta disponible Myme?
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                Por el momento Myme está disponible en Español (latino neutro)  y en Castellano (España) pero pronto estará disponible en Inglés, Portugués, Francés…<br/>
                                                Te recomendamos que utilices el filtro "Idioma" en la galería de plantillas para conocer las que tienes disponibles en cada uno.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                </Accordion>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-12">
                            <div className="image"></div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 faq-button">
                            <a href={`${process.env.REACT_APP_BASE_URL}/faq`} className="btn btn-primary" >Ver más</a>
                        </div>
                    </div>

                </div>
            </section>
        );
    }
}

export default Faq;