import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import {useHistory, useLocation} from "react-router-dom";
import ReactHtmlParser from "react-html-parser";

import TagManagerService from "../../../../services/google-tag-manager/tag-manager.service";
import hotjarService from "../../../../services/hotjar/hotjar.service";
import { cookieConsentConstants } from "../../../constants/cookieConsent";

import CookieSettingsModal from "../CookieSettingsModal";

import "./CookieConsent.scss";

/**
 * Banner for cookie consent.
 *
 * It's shown the first time the page is loaded or after deleting cookies.
 *
 * Contains 2 buttons: "Accept all" and "Cookie Settings"
 */
const CookieConsent = () => {
  const history = useHistory();
  const useQuery = () => new URLSearchParams(useLocation().search);
	let query = useQuery();

  const [cookies, setCookie] = useCookies(["cookie_consent"]);

  const strings = cookieConsentConstants["es"];
  const [showComponent, setShowComponent] = useState(false);
  const [partner, setPartner] = useState();
  const [campaign, setCampaign] = useState();
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const date = new Date();
  const cookieExpDate = new Date(date.setMonth(date.getMonth() + 6));
  const campaignExpDate = new Date(date.setMonth(date.getMonth() + 2))

  const initializeTracking = () => {
    TagManagerService.initialize();
    hotjarService.initialize();
  };

    useEffect(() =>{
    if (query.has('partner')) {
      setCookie("partner_campaign", `${query.get('partner')}, ${query.get('campaign')}`, {expires: campaignExpDate, domain: "myme.net"})
      console.log(cookies)
		}
  }, [])

  useEffect(() => {
    history.location.pathname !== "/cookie-privacy" && setShowComponent(!Boolean(cookies.cookie_consent));
    cookies.cookie_consent?.includes("functional") && initializeTracking();
    console.log(campaignExpDate)

  }, [cookies]);

  return (
    <>
      {showComponent ? (
        <div className="cookie-consent-container">
          <div className="background"></div>

          <div className="banner-container">
            <p> {ReactHtmlParser(strings && strings.text1)}</p>
            <div className="cookie-button-container">
              <button
                className="cookie-accept-button"
                onClick={() => {
                  setCookie("cookie_consent", "strict, functional", {expires: cookieExpDate})
                }}
              >
                {strings.accept_button}
              </button>
              <button className="cookie-settings-button" onClick={() => setShowSettingsModal(!showSettingsModal)}>
                {strings.settings_button}
              </button>
            </div>
          </div>
        </div>
      ) : null}
      {showSettingsModal ? <CookieSettingsModal setShowModal={setShowSettingsModal} /> : null}
    </>
  );
};

export default CookieConsent;
