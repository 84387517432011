import React, { useState } from "react";
import VideoPlayer from "./VideoPlayer";
import VideoOptions from "./VideoOptions";
import "./Spot.scss";

/**
 * Show the Spot video and customization form
 */
const Spot = () => {
  const [videoUrl, setVideoUrl] = useState(
    "https://myme-public.s3-eu-west-1.amazonaws.com/spot_videos/Video_Head_01_w_audio.mp4"
  );
  const [ctaText, setCtaText] = useState("¡Haz tu prueba gratuita!");
  const [ctaUrl, setCtaUrl] = useState(window.location.href);
  const [loop, setLoop] = useState(true);

  return (
    <div className="spot-container">
      <VideoPlayer videoUrl={videoUrl} ctaText={ctaText} ctaUrl={ctaUrl} loop={loop} />
      <VideoOptions setVideoUrl={setVideoUrl} setCtaText={setCtaText} setCtaUrl={setCtaUrl} setLoop={setLoop} />
    </div>
  );
};

export default Spot;
