import React, { useState, useRef, useEffect } from "react";
import { useCookies } from "react-cookie";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import { PropTypes } from "prop-types";

import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { cookieSettingsModalConstants } from "../../../constants/cookieSettingsModal";
import "./CookieSettingsModal.scss";

const COOKIES_PREFIXES = ["_ga", "_gi", "_hj"];

/**
 * Modal component for the cookie setttings.
 *
 * It can be opened from the CookieConsent banner or from the footer.
 *
 * When the component is opened from the footer and the user deactivate configurable cookies
 * the page will reload in order to kill processes like gtm or hotjar
 */
const CookieSettingsModal = ({ setShowModal, isFromFooter }) => {
  const strings = cookieSettingsModalConstants["es"];
  const [cookies, setCookie, removeCookie] = useCookies(["cookie_consent"]);

  const contentRef = useRef(null);

  const [selectedType, setSelectedType] = useState(strings.cookie_info[0]);
  const [reloadPage, setReloadPage] = useState(false);

  const date = new Date();
  const cookieExpDate = new Date(date.setMonth(date.getMonth() + 6));

  const [checked, setChecked] = useState(() => {
    let sel = {};
    cookies["cookie_consent"]
        ? strings.cookie_types.forEach((type) => {
          sel[type.value] = cookies["cookie_consent"].includes(type.value);
        })
        : strings.cookie_types.forEach((type) => {
          sel[type.value] = type.default;
        });
    return sel;
  });

  const handleChange = (event, value) => {
    console.log('handleChange event: ', event)
    console.log('handleChange value: ', value)
    console.log('handleChange value: ')

    // Revisar el value y el null
    if (value) {
      setSelectedType(value)
    }
  };

  const handleSetCookie = () => {
    let checkedString = "";
    strings.cookie_types.forEach(
        (type) => checked[type.value] === true && (checkedString = checkedString.concat(type.value, ","))
    );
    setCookie("cookie_consent", checkedString, { expires: cookieExpDate });
    setShowModal(false);
  };

  const removeConfigurableCookies = () => {
    COOKIES_PREFIXES.forEach((prefix) => {
      for (var key in cookies) {
        if (key.includes(prefix)) {
          removeCookie(key);
        }
      }
    });
  };

  const handleSetChecked = (e) => {
    setChecked({ ...checked, [selectedType.value]: e.target.checked });
    if (isFromFooter & !e.target.checked) {
      // only when component is opened from footer. The first time is not necesary to remove ccookies and reload page
      removeConfigurableCookies();
      setReloadPage(true); // page will reload after clicking "Aceptar" to kill gtm and hotjar sessions
    }
  };

  const handleClickAccept = () => {
    handleSetCookie();
    reloadPage && window.location.reload();
  };

  useEffect(() => {
    contentRef.current.scrollIntoView();
  }, [selectedType]);

  return (
      strings.cookie_types && (
          <div className="cookie-settings-modal-container">
            <div className="title-container">
              <p>{strings.title}</p>
            </div>

            <div className="body-container">
              <ToggleButtonGroup
                  className="menu"
                  orientation="vertical"
                  value={selectedType}
                  exclusive
                  onChange={handleChange}
              >
                {strings.cookie_info.map((info) => (
                    <ToggleButton className="menu-button" key={info.value} value={info}>
                      <p className={"cookie-title"}>{info.name}</p>
                    </ToggleButton>
                ))}
                ,
                {strings.cookie_types.map((type) => (
                    <ToggleButton className="menu-button" key={type.value} value={type}>
                      <p className={"cookie-title"}>{type.name}</p>
                    </ToggleButton>
                ))}
              </ToggleButtonGroup>

              <div className="content">
                {/* {(type = strings.cookie_types.find((type) => type.value === selectedType)) => {
              return <p>{type.description}</p>;
            }} */}
                <div ref={contentRef}></div>

                <div className="select">
                  {selectedType.hasOwnProperty("selectable") &&
                      (selectedType.selectable ? (
                          <Toggle
                              checked={checked[selectedType.value]}
                              // onChange={(e) => setChecked({ ...checked, [selectedType.value]: e.target.checked })}
                              onChange={(e) => handleSetChecked(e)}
                          />
                      ) : (
                          <p>{strings.always_active}</p>
                      ))}
                </div>
                <p className="text1">{selectedType.description1}</p>
                <p><a className="textLink" href={`${process.env.REACT_APP_BASE_URL}/cookie-privacy`}> {selectedType.descriptionLink} </a></p>
                <p className="text1">{selectedType.description2}</p>
                <p className="text1">{selectedType.description3}</p>
              </div>
            </div>

            <div className="buttons-container">
              <button className="save-button" onClick={() => handleClickAccept()}>
                {strings.accept_button}
              </button>
              <button className="cancel-button" onClick={() => setShowModal(false)}>
                {strings.cancel_button}
              </button>
            </div>
          </div>
      )
  );
};

CookieSettingsModal.propTypes = {
  /** When true the modal is active */
  setShowModal: PropTypes.bool,
  /** Must be true whe the component is opened fron the footer */
  isFromFooter: PropTypes.bool,
};

export default CookieSettingsModal;
