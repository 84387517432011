import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';

class MainBanner extends React.Component {
    render(){
        return (
            <>
                <div id="home" className="main-banner">
                    <video loop muted autoPlay poster="#" className="video-background" data-reactid=".0.1.0.0">
                        <source data-reactid=".0.1.0.0" src={require("../../assets/video/promo-video.mp4")} type="video/mp4" />
                    </video>
                    <div className="d-table">
                        <div className="d-table-cell">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                        <div className="main-banner-text">
                                            {/*<h4>myme</h4>*/}
                                            <p className="main-text">¿Qué ocurre cuando te diriges a cada cliente por su nombre? </p>
                                            <h1>Descubre la primera plataforma de <br/> <span>videomarkerting</span> personalizado</h1>
                                            <p> Myme es una nueva forma de comunicarte con tus clientes. <br/>
                                                Gracias a nuestra tecnología, podrás dirigirte a cada uno de ellos por su nombre<br/>
                                                con vídeos personalizados que les sorprenderán y despertarán su interés.</p>
                                            {/*Botones*/}
                                            <a href={`${process.env.REACT_APP_BASE_URL}/signup`} className="btn btn-primary"> Empieza gratis</a>
                                            <AnchorLink href="#welcome" className="btn btn-primary view-work">Ver más</AnchorLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default MainBanner;