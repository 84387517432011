import React from 'react';
import { Link } from 'react-router-dom';

class Welcome extends React.Component {
    render(){
        return (
            <section id="welcome" className="welcome-area ptb-80 bg-f6f6f6">
                <div className="container">
                    <div className="section-title">
                        {/*<h4>We are creative</h4>*/}
                        {/*<h2>Welcome to <span>myme</span></h2>*/}
                        {/*<p>Myme es una nueva forma de comunicarte con tus clientes. <br/> Gracias a nuestra tecnología, podrás dirigirte a cada uno de ellos por su nombre, con vídeos personalizados que les sorprenderán y despertarán su interés.</p>*/}
                    </div>

                    <div className="row">
                        <div className="col-lg-3 col-md-6">
                            <div className="single-box">
                                <i className="fa fa-user icon"></i>
                                <h3>Personalización</h3>
                                <p>Dirígite a cada uno de tus clientes por su nombre y ofréceles contenido individualizado</p>
                                {/*<Link to="#" title="Read More" className="link-btn"><i className="fa fa-arrow-right"></i></Link>*/}
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                            <div className="single-box">
                                {/*<i className="fa fa-laptop icon"></i>*/}
                                <i className="fa fa-video-camera icon"></i>
                                <h3>Vídeo</h3>
                                <p>Crea vídeos únicos en sólo 5 pasos</p>
                                {/*<Link to="#" title="Read More" className="link-btn"><i className="fa fa-arrow-right"></i></Link>*/}
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6">
                            <div className="single-box">
                                {/*<i className="fa fa-laptop icon"></i>*/}
                                <i className="fa fa-sticky-note icon"></i>
                                <h3>Plantillas</h3>
                                <p>Elige el tipo de comunicación y al prescriptor que más se adapte a tus necesidades</p>
                                {/*<Link to="#" title="Read More" className="link-btn"><i className="fa fa-arrow-right"></i></Link>*/}
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 offset-lg-0">
                            <div className="single-box">
                                <i className="fa fa-eur icon"></i>
                                <h3>Económico</h3>
                                <p>Todo ello por mucho menos de lo que imaginas</p>
                                {/*<Link to="#" title="Read More" className="link-btn"><i className="fa fa-arrow-right"></i></Link>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Welcome;