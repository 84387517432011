import React from 'react';
import ModalVideo from 'react-modal-video';
import { Link } from 'react-router-dom';
import videoImg from '../../assets/images/fondo-video.png';

class Skill extends React.Component {

    // Agregar clase hidden
    showHidden() {
        let elementId11 = document.getElementById("show1");
        document.addEventListener("click", () => {
            elementId11.classList.add("video-hidden");
            elementId11.classList.remove("skill-video");
        });
    }

    constructor(props) {
        super(props);
        this.state = { showTitle: false };
    }

    state = {
        isOpen: false
    }

    // openModal = () => {
    //     this.setState({isOpen: true})
    // }




    render(){
        return (
            <section className="skill-area ptb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="about-skill">
                                <div className="section-title">
                                    <h2> Aprende cómo crear <br/> <span> tu campaña en myme</span></h2>
                                </div>

                                {/*<h3 className="progress-title">Branding</h3>*/}
                                {/*<div className="progress">*/}
                                {/*    <div className="progress-bar" style={{width: "92%"}}>*/}
                                {/*        <div className="progress-value">92%</div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}

                                {/*<h3 className="progress-title">Personalización</h3>*/}
                                {/*<div className="progress">*/}
                                {/*    <div className="progress-bar" style={{width: "92%"}}>*/}
                                {/*        <div className="progress-value">92%</div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}

                                {/*<h3 className="progress-title">Marketing</h3>*/}
                                {/*<div className="progress">*/}
                                {/*    <div className="progress-bar" style={{width: "97%"}}>*/}
                                {/*        <div className="progress-value">97%</div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}

                                {/*<h3 className="progress-title">Design</h3>*/}
                                {/*<div className="progress">*/}
                                {/*    <div className="progress-bar" style={{width: "90%"}}>*/}
                                {/*        <div className="progress-value">90%</div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}

                                {/*<h3 className="progress-title">Development</h3>*/}
                                {/*<div className="progress mb-0">*/}
                                {/*    <div className="progress-bar" style={{width: "95%"}}>*/}
                                {/*        <div className="progress-value">95%</div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>
                        </div>

                        {/*<div className="col-md-6">*/}
                        {/*    <div className="skill-video">*/}
                        {/*        <img src={videoImg} alt="video-img" />*/}
                        {/*        <div className="video-btn">*/}
                        {/*            <Link*/}
                        {/*                onClick={e => {e.preventDefault(); this.openModal()}}*/}
                        {/*                to="#"*/}
                        {/*                className="popup-youtube"*/}
                        {/*            >*/}
                        {/*                <i className="fa fa-play"></i>*/}
                        {/*            </Link>*/}
                        {/*            <ModalVideo*/}
                        {/*                channel='custom'*/}
                        {/*                isOpen={this.state.isOpen}*/}
                        {/*                frameBorder="0"*/}
                        {/*                allow="autoplay; fullscreen"*/}
                        {/*                allowFullScreen*/}
                        {/*                url="https://myme-public.s3-eu-west-1.amazonaws.com/TutoMyme_CrearCamp_LAT.mp4"*/}
                        {/*                onClose={() => this.setState({isOpen: false})}*/}
                        {/*            />*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        <div className="col-12 col-lg-6">
                            <div id="show1" className="skill-video">
                                <img src={videoImg} alt="video-img" />
                                <div className="video-btn">
                                    <Link className="popup-youtube"
                                          onClick={e => {
                                              e.preventDefault();
                                              this.showHidden();
                                              this.setState({ showTitle: true })
                                          }}>  <i className="fa fa-play"></i>  </Link>
                                </div>
                            </div>
                            <div className="skill-video">
                                {this.state.showTitle && (
                                    <iframe
                                        isOpen={this.state.isOpen}
                                        // width="420" height="315"
                                        allowFullScreen
                                        src="https://myme-public.s3-eu-west-1.amazonaws.com/TutoMyme_CrearCamp_LAT.mp4">
                                    </iframe>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Skill;