
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import { withRouter } from "react-router-dom";
import * as yup from "yup";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import userServices from "../../services/myme-server/user";
import 'isomorphic-fetch';
// import Modal from "../Common/Modal/Modal";
import Modal from 'react-modal';


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

const ContactNew = () => {

     const [isSubmitted, setIsSubmitted] = useState(false);
    const [subjectOptions, setSubjectOptions] = useState([]);
    const [emailDirections, setEmailDirections] = useState([]);
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }


    useEffect(() => {

    }, [window.location.pathname]);

    useEffect(() => {
        setEmailDirections([
            process.env.REACT_APP_EMAIL_SUPPORT,
            process.env.REACT_APP_EMAIL_CAMPAIGN_PROBLEMS,
            process.env.REACT_APP_EMAIL_PLAN_PROBLEMS,
            process.env.REACT_APP_EMAIL_GENERAL,
        ]);
        setSubjectOptions([
            "Soporte técnico",
            "Problemas con mi campaña",
            "Problemas con mi plan/facturas",
            "Escribe tu asunto",
        ]);
    }, []);



    useEffect(() => {}, [isSubmitted]);

    const initialValues = {
        name: "",
        email: "",
        subjectSelect: 0,
        subject: "",
        text: "",
        to: "",
        check: false,
    };


    let validationSchema = yup.object().shape({
        name: yup
            .string()
            .max(100, "Este campo no puede exceder de los 100 caracteres"),
        email: yup
            .string()
            .email("Este campo no tiene formato de correo")
            .max(100,"Este campo no puede exceder de los 100 caracteres")
            .required("Este campo es obligatorio"),
        subjectSelect: yup
            .number()
            .required("Este campo es obligatorio"),
        subject: yup.string().when("subjectSelect", {
            is: 3,
            then: yup
                .string()
                .max(100,"Este campo no puede exceder de los 100 caracteres")
                .required("Este campo es obligatorio"),
            otherwise: yup
                .string()
                .max(100,"Este campo no puede exceder de los 100 caracteres")
        }),
        text: yup
            .string()
            .max(200,"Este campo no puede exceder de los 100 caracteres")
            .required("Este campo es obligatorio"),
        check: yup.boolean(),
    });


    const handleSubmit = (values) => {
        if (values.subjectSelect !== 3) {
            values.subject = subjectOptions[values.subjectSelect];
        }
        values.to = emailDirections[values.subjectSelect];

        userServices
            .send_contact(values.name, values.email, values.subject, values.text, values.to, values.check)
            .then((data) => {
                if (data.status === 200) {
                    setIsSubmitted(true);
                }
            });
    };

    return (
        <section id="contact" className="contact-area ptb-80 bg-f6f6f6">
            <div className="container">
                <div className="section-title">
                    {/*<h4>Get in Touch</h4>*/}
                    <h2> <span>Contacta</span> con nosotros</h2>
                    <p>¿Tienes más preguntas? Mándanos un mensajito. <br/>
                        Rellena nuestro formulario o escríbenos a<a href="mailto:hello@myme.net"> hello@myme.net</a></p>
                </div>

                <div className="row">
                    {/*Mapa*/}
                    <div className="col-lg-6 col-md-12">
                        <iframe
                            title="Google Map"
                            src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=P.%C2%BA%20de%20la%20Castellana,%2056,%2028046%20Madrid+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                            width="100%"
                            height="453"
                            loading="lazy"
                            marginWidth="0"
                        ></iframe>

                        <div className="contact-info">
                            <ul>
                                <li>
                                    <i className="fa fa-map-marker"></i> Paseo de la Castellana 56, 1º Dcha. 28046, Madrid, Spain
                                </li>
                                <li>
                                    <i className="fa fa-envelope"></i>
                                    <a href="mailto:hello@myme.net">hello@myme.net</a>
                                </li>
                                <li>
                                    <i className="fa fa-phone"></i>
                                    <a href="tel:124412-2445515">(+34) 910 88 22 02</a>
                                </li>
                                {/*<li>*/}
                                {/*    <i className="fa fa-fax"></i> */}
                                {/*    <a href="tel:617-241-60055">617-241-60055</a>*/}
                                {/*</li>*/}
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">

                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={(values) => {
                                handleSubmit(values);
                            }}
                        >
                            {(formik) => {
                                const { values, errors, touched, handleChange } = formik;
                                return !isSubmitted ? (
                                    <div>
                                        {window.innerWidth < 800 ?


                                            // Formulario movil

                                            <div className="contact-mobile">
                                                <button className="toggle-button btn btn-primary"
                                                        onClick={openModal}>Enviar mensaje</button>
                                                <Modal isOpen={modalIsOpen}
                                                       onRequestClose={closeModal}
                                                       style={customStyles}
                                                       contentLabel="Example Modal">

                                                    <div className="contact-form">
                                                        {/*<h4>Contacto</h4>*/}

                                                        <Form className="contactForm">
                                                            {/*<h1 className={"contact-title"}>Contacto</h1>*/}
                                                            <div className="actions">
                                                                <button className="toggle-button" onClick={closeModal}>X</button>
                                                            </div>
                                                            <TextField
                                                                className="contact-form-input form-control"
                                                                name="name"
                                                                label="Nombre"
                                                                margin="dense"
                                                                variant="outlined"
                                                                helperText={Boolean(errors.name) && touched.name && errors.name}
                                                                error={Boolean(errors.name) && touched.name}
                                                                value={values.name}
                                                                inputProps={{ maxLength: 50 }}
                                                                onChange={handleChange}
                                                                // onBlur={props.handleBlur}
                                                                fullWidth
                                                            />
                                                            <TextField
                                                                className="contact-form-input form-control"
                                                                name="email"
                                                                label="Email"
                                                                margin="dense"
                                                                variant="outlined"
                                                                helperText={Boolean(errors.email) && touched.email && errors.email}
                                                                error={Boolean(errors.email) && touched.email}
                                                                value={values.email}
                                                                onChange={handleChange}
                                                                // onBlur={props.handleBlur}
                                                                fullWidth
                                                            />
                                                            <div>
                                                                <p className="contact-form-0">Asunto</p>
                                                            </div>
                                                            {/*<div className="contact-select">*/}
                                                            {/*    <Select*/}
                                                            {/*        MenuProps={"selectMenu"}*/}
                                                            {/*        classes={"select_plantillas"}*/}
                                                            {/*        id="demo-simple-select"*/}
                                                            {/*        name="subjectSelect"*/}
                                                            {/*        value={values.subjectSelect}*/}
                                                            {/*        onChange={handleChange}*/}
                                                            {/*        // onBlur={props.handleBlur}*/}
                                                            {/*        fullWidth*/}
                                                            {/*    >*/}
                                                            {/*        {subjectOptions.map((item, index) => {*/}
                                                            {/*            return (*/}
                                                            {/*                <MenuItem key={"menu-item-" + index.toString()} value={index}>*/}
                                                            {/*                    {item}*/}
                                                            {/*                </MenuItem>*/}
                                                            {/*            );*/}
                                                            {/*        })}*/}
                                                            {/*    </Select>*/}
                                                            {/*</div>*/}
                                                            {values.subjectSelect === 3 ? (
                                                                <TextField
                                                                    className=""
                                                                    name="subject"
                                                                    label="Asunto"
                                                                    margin="dense"
                                                                    variant="outlined"
                                                                    helperText={Boolean(errors.subject) && touched.subject && errors.subject}
                                                                    error={Boolean(errors.subject) && touched.subject}
                                                                    value={values.subject}
                                                                    onChange={handleChange}
                                                                    // onBlur={props.handleBlur}
                                                                    fullWidth
                                                                />
                                                            ) : null}

                                                            <TextField
                                                                className="contact-container-2 form-control"
                                                                name="text"
                                                                label="Mensaje"
                                                                margin="dense"
                                                                variant="outlined"
                                                                helperText={Boolean(errors.text) && touched.text && errors.text}
                                                                error={Boolean(errors.text) && touched.text}
                                                                value={values.text ? values.text : ""}
                                                                inputProps={{ maxLength: 200 }}
                                                                onChange={handleChange}
                                                                // onBlur={props.handleBlur}
                                                                multiline
                                                                rows={4}
                                                                rowsMax={6}
                                                                fullWidth
                                                            />

                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        className="contact-check"
                                                                        checked={values.check}
                                                                        onChange={handleChange}
                                                                        name="check"
                                                                        color="primary"
                                                                    />
                                                                }
                                                                label="¿Quieres recibir una copia de este correo?"
                                                            />

                                                            <button
                                                                type="submit"
                                                                color="primary"
                                                                className={"btn btn-primary"}
                                                                //   onClick={() => gtmContactEvent(values.subjectSelect)}
                                                            >
                                                                Enviar
                                                            </button>
                                                        </Form>
                                                    </div>
                                                </Modal>
                                            </div>
                                            :


                                            // Formulario Desktop
                                            <div className="contact-form">
                                                <h4>Contacto</h4>

                                                <Form className="contactForm">
                                                    {/*<h1 className={"contact-title"}>Contacto</h1>*/}
                                                    <TextField
                                                        className="contact-form-input form-control"
                                                        name="name"
                                                        label="Nombre"
                                                        margin="dense"
                                                        variant="outlined"
                                                        helperText={Boolean(errors.name) && touched.name && errors.name}
                                                        error={Boolean(errors.name) && touched.name}
                                                        value={values.name}
                                                        inputProps={{ maxLength: 50 }}
                                                        onChange={handleChange}
                                                        // onBlur={props.handleBlur}
                                                        fullWidth
                                                    />
                                                    <TextField
                                                        className="contact-form-input form-control"
                                                        name="email"
                                                        label="Email"
                                                        margin="dense"
                                                        variant="outlined"
                                                        helperText={Boolean(errors.email) && touched.email && errors.email}
                                                        error={Boolean(errors.email) && touched.email}
                                                        value={values.email}
                                                        onChange={handleChange}
                                                        // onBlur={props.handleBlur}
                                                        fullWidth
                                                    />
                                                    <div>
                                                        <p className="contact-form-0">Asunto</p>
                                                    </div>
                                                    {/*<div className="contact-select">*/}
                                                    {/*    <Select*/}
                                                    {/*        MenuProps={"selectMenu"}*/}
                                                    {/*        classes={"select_plantillas"}*/}
                                                    {/*        id="demo-simple-select"*/}
                                                    {/*        name="subjectSelect"*/}
                                                    {/*        value={values.subjectSelect}*/}
                                                    {/*        onChange={handleChange}*/}
                                                    {/*        // onBlur={props.handleBlur}*/}
                                                    {/*        fullWidth*/}
                                                    {/*    >*/}
                                                    {/*        {subjectOptions.map((item, index) => {*/}
                                                    {/*            return (*/}
                                                    {/*                <MenuItem key={"menu-item-" + index.toString()} value={index}>*/}
                                                    {/*                    {item}*/}
                                                    {/*                </MenuItem>*/}
                                                    {/*            );*/}
                                                    {/*        })}*/}
                                                    {/*    </Select>*/}
                                                    {/*</div>*/}
                                                    {values.subjectSelect === 3 ? (
                                                        <TextField
                                                            className=""
                                                            name="subject"
                                                            label="Asunto"
                                                            margin="dense"
                                                            variant="outlined"
                                                            helperText={Boolean(errors.subject) && touched.subject && errors.subject}
                                                            error={Boolean(errors.subject) && touched.subject}
                                                            value={values.subject}
                                                            onChange={handleChange}
                                                            // onBlur={props.handleBlur}
                                                            fullWidth
                                                        />
                                                    ) : null}

                                                    <TextField
                                                        className="contact-container-2 form-control"
                                                        name="text"
                                                        label="Mensaje"
                                                        margin="dense"
                                                        variant="outlined"
                                                        helperText={Boolean(errors.text) && touched.text && errors.text}
                                                        error={Boolean(errors.text) && touched.text}
                                                        value={values.text ? values.text : ""}
                                                        inputProps={{ maxLength: 200 }}
                                                        onChange={handleChange}
                                                        // onBlur={props.handleBlur}
                                                        multiline
                                                        rows={4}
                                                        rowsMax={6}
                                                        fullWidth
                                                    />

                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                className="contact-check"
                                                                checked={values.check}
                                                                onChange={handleChange}
                                                                name="check"
                                                                color="primary"
                                                            />
                                                        }
                                                        label="¿Quieres recibir una copia de este correo?"
                                                    />

                                                    <button
                                                        type="submit"
                                                        color="primary"
                                                        className={"btn btn-primary"}
                                                        //   onClick={() => gtmContactEvent(values.subjectSelect)}
                                                    >
                                                        Enviar
                                                    </button>
                                                </Form>
                                            </div>
                                        }
                                    </div>

                                ) : (
                                    // Form al enviar
                                    <div className="contact-form-send">
                                        <p className="contact-form-padding">Muchas gracias por tu consulta. Te responderemos lo antes posible.</p>
                                        <p className="contact-form-padding">Un saludo del equipo Myme!</p>
                                    </div>
                                );
                            }}
                        </Formik>


                    </div>
                </div>
            </div>
        </section>
    );
};

ContactNew.propTypes = {
    authorizeAccess: PropTypes.func, // App
    setRedirect: PropTypes.func, // App
    closeModal: PropTypes.func, // Header
    errors: PropTypes.object, // Formik
    handleBlur: PropTypes.func, // Formik
    handleChange: PropTypes.func, // Formik
    handleSubmit: PropTypes.func, // Formik
    history: PropTypes.object, // Router
    isSubmitting: PropTypes.bool, // Formik
    touched: PropTypes.object, // Formik
    values: PropTypes.object, // Formik
};

export default withRouter(ContactNew);







