import TagManager from "react-gtm-module";

/**
 *  Tag Manager  Service Class
 */
export default class TagManagerService {
  //static properties
  static initialized = false;
  static tagManagerArgs = {
    dataLayerName: "PageDataLayer",
    gtmId: process.env.REACT_APP_GTM_KEY,
  };

  //static methods
  /**
   * Initialize gtm.
   *
   * Call this function to initialize gtm. Once initiallized, the only way to kill the process is by reloading the page.
   */
  static initialize() {
    localStorage.getItem("myme_auth")
      ? TagManager.initialize({
          ...this.tagManagerArgs,
          dataLayer: { userId: JSON.parse(localStorage.getItem("myme_auth"))?.user.toString() },
        })
      : TagManager.initialize(this.tagManagerArgs);
    this.initialized = true;
  }

  /**
   * Push and event to gtm project. It must have been initilized before.
   *
   * @param {string} event
   * @param {string} value
   */
  static pushEvent(event, value = null) {
    this.initialized &&
      TagManager.dataLayer({ dataLayerName: "PageDataLayer", dataLayer: value ? { event, value } : { event } });
  }

  /**
   * Set an userId for gtm dataLayer.
   *
   * Once the value is set it will remain in the dataLayaer.
   *
   * @param {string} userId
   */
  static setUserId(userId = null) {
    this.initialized && TagManager.dataLayer({ dataLayerName: "PageDataLayer", dataLayer: userId ? { userId } : {} });
  }

  /**
   * Remove userId.
   *
   * This method reinitialized the gtm dataLayer and then delete the userId.
   * Use it when the user logs out.
   */
  static removeUserId() {
    this.initialized && TagManager.initialize(this.tagManagerArgs);
  }
}
