import { hotjar } from "react-hotjar";

const hjid = parseInt(process.env.REACT_APP_HOTJAR_ID);
const hjsv = parseInt(process.env.REACT_APP_HOTJAR_SV);

/**
 * Initialize hotjar.
 *
 * To initialize hotjar just import and call this function.
 * There is not possible to kill the process by another call, the only way is by reloading the page.
 */
const initialize = () => {
  hotjar.initialize(hjid, hjsv);
  // <!-- Optional identify call -->
  // hotjar.identify("USER_ID", { userProperty: "value" });
  // hotjar.identify(1, { userProperty: "value" });
  // console.log("initialize hotjar");
};

export default { initialize };
