import React from 'react';
import Lightbox from "react-image-lightbox";
import 'react-image-lightbox/style.css';
import { Link } from 'react-router-dom';
import mixitup from "mixitup";
import AnchorLink from "react-anchor-link-smooth-scroll/lib/anchor-link";

const images = [
    require("../../assets/images/templates/Cumple_promo.jpg"),
    require("../../assets/images/templates/Agradece_compra.jpg"),
    require("../../assets/images/templates/Cita_Recordatorio.jpg"),
    require("../../assets/images/templates/Restaurantes_fidelizacion.jpg"),
    require("../../assets/images/templates/Cursos_Online.jpg"),
    require("../../assets/images/templates/Rebajas3_ESP.jpg")
];

const highlightedTemplates = [
    {
        title: 'Felicita el cumpleaños + Promo',
        description: ' Felicita a tus clientes en su gran día con una promoción de lo más especial.',
        image: images[0],
        id: 40
    },
    {
        title: 'Agradece compra + Promo',
        description: 'Premia las compras que realicen los usuarios de tu tienda online y fidelízales.',
        image: images[1],
        id: 19
    },
    {
        title: 'Recordatorio Cita',
        description: 'Recuérdales a tus clientes que tienen una cita en tu clínica próximamente.',
        image: images[2],
        id: 43
    },
    {
        title: 'Programa de Fidelización + Promo',
        description: 'Premia a los clientes más habituales de tu restaurante con ofertas y promos únicas.',
        image: images[3],
        id: 42
    },
    {
        title: 'Nuevos cursos Online + Promo',
        description: 'Informa a tu público del nuevo curso online que tienes disponible.',
        image: images[4],
        id: 78
    },
    {
        title: 'Rebajas (Diseño 3) ESP',
        description: 'Desvela a tus clientes los descuentos que has aplicado a tus productos.',
        image: images[5],
        id: 26
    },
]

class Work extends React.Component {
    state = {
        photoIndex: 0,
        isOpen: false
    };

    componentDidMount() {
        mixitup("#mix-wrapper", {
            animation: {
                effects: "fade rotateZ(0deg)",
                duration: 700
            },
            classNames: {
                block: "programs",
                elementFilter: "filter-btn",
            },
            selectors: {
                target: ".mix-target"
            }
        });
    }

    render(){
        const { photoIndex, isOpen } = this.state;
        return (
            <section id="work" className="work-area ptb-80">
                <div className="container">
                    <div className="section-title">
                        <h2>Nuestras <span> Plantillas</span></h2>
                        <p>Myme es contar con mensajes adaptados a tus necesidades. <br/>  Dispondrás de una amplia selección de plantillas temáticas con contenidos promocionales y de fidelización que siempre incluirán un saludo personalizado de uno de nuestros prescriptores con dicción en boca. Sólo en Myme.</p>
                    </div>


                    {/*<div className="row">*/}
                    {/*    <div className="col-lg-12 col-md-12">*/}
                    {/*        <div className="shorting-menu">*/}
                    {/*            <button*/}
                    {/*                className="filter"*/}
                    {/*                data-filter="all"*/}
                    {/*            >*/}
                    {/*                All*/}
                    {/*            </button>*/}
                    {/*            <button*/}
                    {/*                className="filter"*/}
                    {/*                data-filter=".brand"*/}
                    {/*            >*/}
                    {/*                Brand*/}
                    {/*            </button>*/}
                    {/*            <button*/}
                    {/*                className="filter"*/}
                    {/*                data-filter=".design"*/}
                    {/*            >*/}
                    {/*                Design*/}
                    {/*            </button>*/}
                    {/*            <button*/}
                    {/*                className="filter"*/}
                    {/*                data-filter=".graphic"*/}
                    {/*            >*/}
                    {/*                Graphic*/}
                    {/*            </button>*/}
                    {/*            <button*/}
                    {/*                className="filter"*/}
                    {/*                data-filter=".photoshop"*/}
                    {/*            >*/}
                    {/*                Photoshop*/}
                    {/*            </button>*/}
                    {/*            <button*/}
                    {/*                className="filter"*/}
                    {/*                data-filter=".illustrator"*/}
                    {/*            >*/}
                    {/*                Illustrator*/}
                    {/*            </button>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>

                <div className="shorting">
                    <div className="row m-0" id="mix-wrapper">
                    {
                        highlightedTemplates.map((item) => {
                            return <div className="col-6 col-lg-4 col-sm-6 mix p-0 mix-target">
                                <div className="single-work">
                                    <a href={`${process.env.REACT_APP_BASE_URL}/template/${item.id}`} id="enlace-temp" className="enlace-temp"></a>
                                    <img src={item.image} alt="work-img" />

                                    <div className="work-content">
                                        <h4>{item.title}</h4>
                                        <ul>
                                            <li><p>{item.description}</p></li>
                                        </ul>
                                    </div>

                                    <a href={`${process.env.REACT_APP_BASE_URL}/template/${item.id}`} className="popup-btn"
                                    onClick={() => this.setState({ photoIndex: 0, isOpen: true })}><i className="fa fa-plus"></i></a>
                                </div>
                            </div>
                        })
                    }
                    </div>
                </div>
                <div className="work-buttton">
                    <p>Aquí puedes ver algunos ejemplos de tus plantillas pero tendrás muchas más. Escoge el plan que más te guste y descúbrelas todas.</p>
                    <AnchorLink href="#price" className="btn btn-primary arrow-down">
                        <i className="fa fa-arrow-down" aria-hidden="true"></i>
                    </AnchorLink>
                </div>

                {isOpen && (
                    <Lightbox
                        mainSrc={images[photoIndex]}
                        nextSrc={images[(photoIndex + 1) % images.length]}
                        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                        imageTitle={photoIndex + 1 + "/" + images.length}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + images.length - 1) % images.length
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + 1) % images.length
                            })
                        }
                    />
                )}
            </section>
        );
    }
}

export default Work;