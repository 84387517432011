import React from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from "react-visibility-sensor";

class FunfactMetricas extends React.Component {
    state = {
        didViewCountUp: false
    };

    onVisibilityChange = isVisible => {
        if (isVisible) {
            this.setState({ didViewCountUp: true });
        }
    };

    render(){
        return (
            <section className="funFacts-area funFacts-padding ptb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-sm-6">
                            <div className="funFact">
                                <i className="fa fa-paper-plane"></i>
                                <h2 className="count">
                                    <VisibilitySensor
                                        onChange={this.onVisibilityChange}
                                        offset={{
                                            top: 10
                                        }}
                                        delayedCall
                                    >
                                        <CountUp
                                            start={0}
                                            end={
                                                this.state.didViewCountUp
                                                    ? 25
                                                    : 0
                                            }
                                            duration={6}
                                        />
                                    </VisibilitySensor>
                                    <p style={{paddingLeft: "6px"}}> Millones </p>
                                </h2>
                                <p>Vídeos enviados</p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-sm-6">
                            <div className="funFact">
                                <i className="fa fa-smile-o"></i>
                                <h2 className="count">
                                    <VisibilitySensor
                                        onChange={this.onVisibilityChange}
                                        offset={{
                                            top: 10
                                        }}
                                        delayedCall
                                    >
                                        <CountUp
                                            start={0}
                                            end={
                                                this.state.didViewCountUp
                                                    ? 400
                                                    : 0
                                            }
                                            duration={6}
                                        />
                                    </VisibilitySensor>
                                    {/*<p >M</p>*/}
                                </h2>
                                <p> Happy Clients</p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-sm-6">
                            <div className="funFact">
                                <i className="fa fa-sticky-note-o"></i>
                                <h2 className="count">
                                    <VisibilitySensor
                                        onChange={this.onVisibilityChange}
                                        offset={{
                                            top: 10
                                        }}
                                        delayedCall
                                    >
                                        <CountUp
                                            start={0}
                                            end={
                                                this.state.didViewCountUp
                                                    ? 50
                                                    : 0
                                            }
                                            duration={6}
                                        />
                                    </VisibilitySensor>
                                    {/*<p >M</p>*/}
                                </h2>
                                <p> Plantillas dispobibles </p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-sm-6">
                            <div className="funFact">
                                <i className="fa fa-compress"></i>
                                <h2><span className="count">
                                    <VisibilitySensor
                                        onChange={this.onVisibilityChange}
                                        offset={{
                                            top: 10
                                        }}
                                        delayedCall
                                    >
                                        <CountUp
                                            start={0}
                                            end={
                                                this.state.didViewCountUp
                                                    ? 35
                                                    : 0
                                            }
                                            duration={6}
                                        />
                                    </VisibilitySensor>
                                </span>
                                    <p style={{paddingLeft: "6px"}}> Mil </p>
                                </h2>
                                <p>Combinaciones posibles</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default FunfactMetricas;